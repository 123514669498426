import { AppPlugin, AppRootProps } from '@grafana/data';
import { configureGrafanaExtensions } from 'app/grafanaExtensions';
import { AssertsAppJsonData } from 'asserts-types';
import React, { lazy, Suspense } from 'react';
import pluginJson from 'plugin.json';
import FallbackLoadingPlaceholder from 'components/FallbackLoadingPlaceholder';
import { css } from '@emotion/css';
import { useTheme2 } from '@grafana/ui';
import { EntityAssertionsWidgetProps } from 'externalComponents/types';

let initialized = false;
async function init() {
  if (initialized) {
    return;
  }
  initialized = true;
  const { registerPalette } = await import('features/EntityDetails/components/ServiceEntityOverview/colors');
  registerPalette();
}

const LazyApp = lazy(async () => {
  await init();
  return import('./features/App');
});
const ExternalComponentWrapper = lazy(() => import('externalComponents/ExternalComponentWrapper'));
const EntityAssertionsWidget = lazy(() => import('externalComponents/EntityAssertionsWidget/EntityAssertionsWidget'));

const App = (props: AppRootProps<AssertsAppJsonData>) => (
  <Suspense fallback={<FallbackLoadingPlaceholder />}>
    <LazyApp {...props} />
  </Suspense>
);

const plugin = new AppPlugin<AssertsAppJsonData>().setRootPage(App);

if (plugin.exposeComponent) {
  plugin.exposeComponent({
    id: `${pluginJson.id}/entity-assertions-widget/v1`,
    title: 'Entity Assertions Widget',
    description: 'Widget for displaying entity assertions in other apps',
    component: SuspendedAssertionsWidget,
  });
}

function SuspendedAssertionsWidget(props: EntityAssertionsWidgetProps) {
  const theme = useTheme2();
  let fallbackHeight;
  switch (props.size) {
    case 'sm':
      fallbackHeight = theme.components.height.sm;
      break;
    case 'md':
      fallbackHeight = theme.components.height.md;
      break;
    case 'lg':
      fallbackHeight = theme.components.height.lg;
      break;
    default:
      fallbackHeight = theme.components.height.md;
  }

  return (
    <Suspense fallback={<div className={css({ height: fallbackHeight })} />}>
      <ExternalComponentWrapper>
        <EntityAssertionsWidget {...props} />
      </ExternalComponentWrapper>
    </Suspense>
  );
}

configureGrafanaExtensions(plugin);

export { plugin };
